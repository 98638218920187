export const API_PATH: any = {
    //For Accounts
    'API_BASE_PATH': '',
    'AUTH': 'auth',
    'LOGIN': '/login',
    'GOOGLE_LOGIN': '/google-login',
    'GOOGLE_REGISTER': '/google-register',
    'REGISTER': '/register',
    'GET_SIGN_UP': '/get-signup',
    'GENERATE_OTP': '/generate-otp',
    'VERIFY_OTP': '/verify-otp',
    //For daily log
    'GET_DAILY_LOGS': "bids/get-daily-logs",
    'COSTING_DAILY_LOG': 'bids/daily-log',
    //BID REQUEST
    'GET_BID_REQUEST_LIST': 'bids/bid-request-item',
    'GET_BID_REQUEST_DETAILS': 'bids/bid-request',
    'SAVE_BID_REQUEST_DETAILS': 'bids/save-bid-request',
    //APPROVED BIDS
    'GET_APPROVED_BID_LIST': 'bids/bid-approve',
    'GET_APPROVED_BID_DETAILS': 'bids/bid-approve-item',
    //PENDING BIDS
    'GET_PENDING_BID_LIST': 'bids/bid-pending',
    'GET_PENDING_BID_DETAILS': 'bids/bid-pending-item',
    'SAVE_PENDING_BID_DETAILS': 'bids/save-bid-request',
    //DECLINE BIDS
    'GET_DECLINE_BID_LIST': 'bids/bid-decline',
    'GET_DECLINE_BID_DETAILS': 'bids/bid-decline-item',
    'SAVE_DECLINE_BID_DETAILS': 'bids/save-bid-request',
    'ACTIVE_PROJECTS': 'bids/active-projects',
    'VIEW_DAILY_LOGS': 'bids/user-log',
    'BIDS_FILE_UPLOAD': 'bids/file-upload',
    //User detail

    'USER_DETAIL': '/user-details',

    //MY PROFILE
    'GET_PROFILE': 'bids/get-profile',
    'UPDATE_PROFILE': 'bids/update-profile',

    //FORGET PASSWORD
    'GENERATE_OTP_FORGET_PASS': '/forget-password',
    'VERIFY_OTP_FORGET_PASS': '/verify-forget-otp',
    'CREATE_NEW_PASS': '/reset-password',


    //CHANGE PASSWORD
    'CHANGE_PASSWORD': '/change-password',
    'INITIAL_CHANGE_PASSWORD': '/initial-change-password',
    'NOTIFICATION_COUNT': 'bids/notification-count',

    //PM Call
    'BIDS_CALL_PM': 'bids/call-pm',

    'GET_COUNTRY_LIST': 'bids/get-country-list',
    'GET_SUB_CATEGORY': 'bids/get-trade-sub-categories',
    'GET_OTP': '/get-otp',

    'GET_CATEGORIES': '/get-trade-categories',

    'GET_FILES': 'bids/get-files',

    //schedule
    'GET_TASK_CONTRACTOR': 'bids/contractor-get-tasks',
    'GET_SCHEDULE_DETAILS': 'bids/gantt-details',
    'GET_ASSIGNED_TASK': 'bids/contractor-assigned-tasks',

    //projects
    'GET_PROJECTS': 'bids/contractor-active-projects',
    'GENERATE_SALT': '/generate-salt',
    'GET_OTP_SALT': '/get-salt-otp',
    'GET_COMMON_OTP_SALT': '/generate-common-salt',
    'CLEAR_SESSION': 'bids/clear-session',
    'CHECK_CONTRACTOR':'bids/check-contractor',


    'SITE_MANAGEMENT_DAILY_LOGS_VIEW':'bids/view-daily-logs',
    'MULTIPLE_FILE_UPLOAD':'bids/multiple-upload',
    'DELETE_APPROVAL_UPLOAD':'bids/delete-document'
}
